import * as React from 'react';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Page } from '../components/app/Page';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { Typography } from '../components/Typography';
import { SEO } from '../components/app/SEO';
import { Link } from '../components/Link';
import { SocialLink } from '../components/SocialLink';
import { graphql } from 'gatsby';
import { Avatar } from '../components/Avatar';
import { GQLAboutPageQueryQuery } from '../lib/types/graphql/__generated__/gatsby.gql';
import { SocialList } from '../components/SocialList';

type ClassKey = 'main' | 'hero' | 'content' | 'avatar';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        padding: theme.spacing(4),
        maxWidth: '45em',
        margin: '0 auto'
    },
    hero: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.baseline.blockMarginBottom
    },
    content: {},
    avatar: {
        width: '96px',
        height: '96px',
        marginBottom: theme.baseline.blockMarginBottom,
        boxShadow: theme.shadows[6]
    }
}));

export type TAboutP = {
    data: GQLAboutPageQueryQuery;
};

function About(props: TReactFCP<TAboutP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    return (
        <main className={styles.main}>
            <SEO
                slug={props.data.sitePage!.path!}
                title={'About'}
                seoTitle='Dossier'
                description={`Always busy, always hustlin'. Come see what all the fuss is about.`}
            />
            <section className={styles.hero}>
                <Avatar
                    className={styles.avatar}
                    variant='image'
                    image={props.data.file!.childImageSharp!.fixed!}
                />
                <Typography variant='h1'>Hi, I'm John Richter</Typography>
            </section>
            <section>
                <Typography paragraph>
                    {`I'm currently building an event hosting platform for amateur motorsports organizations
                      full time at `}
                    <Link variant='external' to='https://paddock.tech'>Paddock Tech</Link>
                    {`. We empower organizations to plan, manage, and market their race, time trial, and driver
                        education events while participants enjoy the benefits of a unified ecosystem for timing,
                        scoring, and coaching in the paddock, at the track.`}
                </Typography>
                <Typography paragraph>
                    {`In my spare time (depending on how you look at it) I travel across the country with my
                      favorite band of motorhead misfits, `}
                    <Link variant='external' to='https://gtmotorsports.org'>Gran Touring Motorsports (GTM)</Link>
                    {`, to drive new race tracks and help others realize both their own and their car's
                      potential at full throttle. We're over 70 members and continue to grow every year. I
                      co-direct the club's D.C. region of nearly 40 people, occasionally `}
                    <Link variant='external' to='https://www.gtmotorsports.org/?p=9544'>write</Link>
                    {` and `}
                    <Link variant='external' to='https://www.gtmotorsports.org/?p=138'>contribute</Link>
                    {` to articles on the club's blog, and bet you'll find candid shots of `}
                    <SocialLink variant='instagram' disableIcon disableBrandColor enableUnderline>me</SocialLink>
                    {` on `}
                    <SocialLink
                        variant='instagram'
                        handle='grantouringmotorsports'
                        enableUnderline
                        disableIcon
                        disableBrandColor
                    >
                        their Instagram
                    </SocialLink>
                    {`.`}
                </Typography>
                <Typography paragraph>
                    {`As you can imagine, I've gained a lot of experience `}
                    <Link variant='internal' to='/driving/'>driving and coaching</Link>
                    {` over the years.`}
                </Typography>
                <Typography paragraph>
                    {`In my `}
                    <Link variant='internal' to='/blog/'>blog</Link>
                    {` I like to share the wisdom I've gained through the experience of bootstrapping a company,
                    practical tricks I've learned for building attractive, resiliant, and performant software,
                    my adventures in motorsports, and more.`}
                </Typography>
                <Typography paragraph>
                    {`I've also built a few side projects in the past and tend to have one or two that I'm working on at
                    any given time. `}
                    <Link variant='internal' to='/projects/'>Check them out</Link>
                    {` if you have a moment!`}
                </Typography>
                <Typography paragraph>
                    Professionally, I've built a reputation for building and designing high quality applications and
                    websites with scalable, modern backends over the last decade as a software engineer.
                </Typography>
                <Typography paragraph>
                    {`In my previous position I joined my friends at `}
                    <Link variant='external' to='https://qntfy.com/'>Qntfy</Link>
                    {` to combat and prevent suicide using social and behavorial analytics. I also built cutting
                    edge data analysis and security tools at DARPA.`}
                </Typography>
                <Typography paragraph>
                    {`Prior to that I held several positions at the U.S. Department of Defense where I developed
                    computer network exploitation tools, built metrics, monitoring, and performance analysis products
                    for 1000+ node Hadoop clusters, graduated from the DoD's first cohort of `}
                    <Link variant='external' to='https://www.nsf.gov/news/special_reports/i-corps/'>I-Corps</Link>
                    {` (a YC-like accelerator), and spoke at internal technical and security conferences.`}
                </Typography>
                <Typography paragraph>
                    {`Oh yeah, I've also built `}
                    <Link variant='external' to='https://www.oasis-ae.com/embedded-training'>embedded software</Link>
                    {` for light and heavy armored vehicles used by the U.S. military. Discover more about my `}
                    <Link variant='internal' to='/career/'>career -></Link>
                </Typography>
                <Typography paragraph>
                    {`I always keep my comms open. Have an interesting opportunity or just want to say hi? Let's chat.
                    Try `}
                    <SocialLink variant='email' disableIcon enableUnderline>email</SocialLink>
                    {` or `}
                    <SocialLink variant='twitter' enableText enableUnderline disableBrandColor disableIcon />
                    {` first. If I'm M.I.A., odds are one of the other social networks will get my attention. I'll do
                    my best to answer the call!`}
                </Typography>
                <Typography component='div' paragraph align='center'>
                    <SocialList inline SocialLinkProps={{ disableBrandColor: true }} />
                </Typography>
                <Typography paragraph align='center'><span role='img' aria-label='wave'>👋</span></Typography>
            </section>
        </main>
    );
}

export default (props: TReactFCP<TAboutP>): TReactFCR => <Page component={About} cprops={props} />;

export const query = graphql`
    query AboutPageQuery {
        sitePage(internalComponentName: {eq: "ComponentAbout"}) {
            path
        }
        file(relativePath: {eq: "authors/johnrichter/profile.jpeg"}) {
            childImageSharp {
                # The max size of profile photo we will use in Avatar
                fixed(width: 96, height: 96) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`;
