import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { GQLImageSharpFixed } from '../lib/types/graphql/__generated__/gatsby.gql';
import { IconProp, TBaseIconProps } from './FAIcons';
import { CreateCSSProperties } from '@material-ui/styles';
import { FixedObject } from 'gatsby-image';

type TVariant = 'text' | 'icon' | 'image';

type StyleProps = { variant: TVariant; };
type ClassKey = 'container' | 'icon' | 'image' | 'sm' | 'md' | 'lg';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    container(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            lineHeight: 1,
            borderRadius: '50%',
            overflow: 'hidden',
            userSelect: 'none',
        };
        if (props.variant === 'image') {
            style.boxShadow = `inset 0 0 0 1px ${theme.palette.custom.avatar.insetBoxShadow}`;
        } else if (props.variant === 'icon') {
            style.backgroundColor = theme.palette.custom.avatar.icon.background;
            style.color = theme.palette.custom.avatar.icon.color;
        }
        return style;
    },
    iconContainer: {

    },
    icon: {},
    image: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        objectFit: 'cover', // Handle non-square image. The property isn't supported by IE 11.
        zIndex: -1 // Enables inset box shadow borders over the image
    },
    sm: { width: '24px', height: '24px' },
    md: { width: '36px', height: '36px' },
    lg: { width: '48px', height: '48px' },
}));

export type TAvatarP<TIconProps extends {} = {}> = {
    variant?: TVariant;
    className?: string;
    size?: 'sm' | 'md' | 'lg';
    alt?: string;
    image?: GQLImageSharpFixed;
    icon?: IconProp<TIconProps>;
};

export function Avatar<TIconProps extends TBaseIconProps = TBaseIconProps>(
    props: TReactFCP<TAvatarP<TIconProps>>
): TReactFCR {
    const { className, alt, image, variant = 'text', size = 'md' }: TAvatarP<TIconProps> = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ variant });
    const containerStyles: string = clsx(
        styles.container,
        {
            [styles.sm]: size === 'sm',
            [styles.md]: size === 'md',
            [styles.lg]: size === 'lg',
        },
        className
    );
    switch (variant) {
        case 'icon':
            return (
                <div className={containerStyles}>
                    {props.icon && <props.icon.Component className={styles.icon} {...props.icon.props} />}
                </div>
            );
        case 'image':
            return (
                <div className={containerStyles}>
                    <GatsbyImage
                        className={styles.image}
                        fixed={image as FixedObject}
                        alt={alt}
                        // Support IE
                        objectFit="cover"
                        objectPosition="50% 50%"
                    />
                </div>
            );
        case 'text':
        default:
            return <div className={containerStyles}>{props.children}</div>;
    }
}
